<template lang="pug">
  .container.text-center.mt-5
    first-heading.display-1(:title='code')

    p(v-html='$ts("description")')

    router-link(:to='$ts("link")') {{ $ts('label') }}
</template>

<script>
export default {
  name: 'ErrorView',

  props: {
    code: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    i18nScope() {
      return `views.errors.${this.code}`
    },
  },

  mounted() {
    if (this.code === 401) this.$auth.expire()
  },
}
</script>
